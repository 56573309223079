import React, { Component } from "react";
import h337 from "heatmap.js";
import { Line ,Bar } from "react-chartjs-2";
import Selectbd from './../bd/getDb';
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Card, Form, Button, Row, Col } from "react-bootstrap";

class GetReporteGerencial extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const rango2Hasta = this.formatDate(today);
    const rango2Desde = this.formatDate(new Date(today.setDate(today.getDate() - 7)));
    const rango1Hasta = this.formatDate(new Date(today.setDate(today.getDate() - 1)));
    const rango1Desde = this.formatDate(new Date(today.setDate(today.getDate() - 7)));
    this.state = {
      locales: [],
      dataticket:[],
      selectedLocales: [], // ✅ Selecciona todos por defecto
      fechaDesde1: rango1Desde, // Semana anterior
      fechaHasta1: rango1Hasta, // Hasta hace 7 días
      fechaDesde2: rango2Desde, // Últimos 7 días
      fechaHasta2: rango2Hasta, // Hoy
      tipoReporte: "Ventas",
      dataLineChart: null,
      dataLineChartVentas:null,
      chartData:null,
      chartDatT:null
    };
    this.heatmapInstance = null;
  }
 
  // ✅ Convertido en Arrow Function para evitar el error de "is not a function"
  initHeatmap = () => {
    const container = document.getElementById("heatmapContainer");
    if (!container) return;

    this.heatmapInstance = h337.create({
      container: container,
      radius: 30,
      maxOpacity: 0.6,
      minOpacity: 0.2,
      blur: 0.85,
    });
  };

 

  componentDidMount() {
    this.initHeatmap(); // ✅ Ya no dará error porque `initHeatmap` ahora es una arrow function
  }

  handleLocalesChange = (e) => {
    const { options } = e.target;
    const selectedOptions = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);

    /*if (selectedOptions.includes("Todos")) {
      this.setState({ selectedLocales: [...this.state.locales] });
    } else {
      this.setState({ selectedLocales: selectedOptions });
    }*/
      this.setState({ selectedLocales: selectedOptions.includes("Todos") ? this.state.locales : selectedOptions });
  };
  formatDate(date) {
    return date.toISOString().split("T")[0];
  }
  generarReporte = () => {
    console.log("Locales Seleccionados:", this.state.selectedLocales);
    if (this.state.tipoReporte === "Ventas") {
        const { selectedLocales, locales } = this.state;

        // Si están todos seleccionados (o selectedLocales es igual a todos los IDs), mostrar cadena vacía
        const allIds = locales.map(local => local.id_usuario_globales);
        const isAllSelected = selectedLocales.length === allIds.length;

        const resultado = isAllSelected ? "" : selectedLocales.join(",");
        let SQL_add= ""
        if(resultado){
            SQL_add=" and id_usuario_globales in("+resultado+")"
        }
        const SQL ="select cast(ticket1 as decimal(20,2)) as ticket1,dia1,cast(ticket2 as decimal(20,2)) as ticket2,dia2 from (SELECT (sum(subtotal/transacciones))/count(*) as ticket1,WEEKDAY(fecha) as dia1 FROM ventas  where fecha>='"+(this.state.fechaDesde1)+" 00:00:00' and  fecha<='"+(this.state.fechaHasta1)+" 23:59:59' "+SQL_add+" group by WEEKDAY(fecha)) a inner join (SELECT (sum(subtotal/transacciones))/count(*) as ticket2,WEEKDAY(fecha) as dia2 FROM ventas where  fecha>='"+(this.state.fechaDesde2)+" 00:00:00' and  fecha<='"+(this.state.fechaHasta2)+" 23:59:59' "+SQL_add+" group by WEEKDAY(fecha)) b on a.dia1=b.dia2" 
        const SQLV ="select cast(ticket1 as decimal(20,2)) as ticket1,dia1,cast(ticket2 as decimal(20,2)) as ticket2,dia2 from (SELECT (sum(subtotal)) as ticket1,WEEKDAY(fecha) as dia1 FROM ventas  where fecha>='"+(this.state.fechaDesde1)+" 00:00:00' and  fecha<='"+(this.state.fechaHasta1)+" 23:59:59' "+SQL_add+" group by WEEKDAY(fecha)) a inner join (SELECT (sum(subtotal)) as ticket2,WEEKDAY(fecha) as dia2 FROM ventas where  fecha>='"+(this.state.fechaDesde2)+" 00:00:00' and  fecha<='"+(this.state.fechaHasta2)+" 23:59:59' "+SQL_add+" group by WEEKDAY(fecha)) b on a.dia1=b.dia2" 
        
        const select = [{
            type: 'consulta', data:
            [{
            table : "("+SQL+")",
            field :[],
            order_by: "dia1",
            return:['ticket1','dia1','ticket2','dia2']
            },
            {
              table : "("+SQLV+")",
              field :[],
              order_by: "dia1",
              return:['ticket1','dia1','ticket2','dia2']
            },
            {
              table : "( SELECT tipo1,cast(sum(subtotal) as decimal(20,2)) as valor FROM venta_tipo_pagov2 v inner join tipo_pago t  on t.forma_pago=v.forma_pago where fecha>='"+(this.state.fechaDesde2)+" 00:00:00' and  fecha<='"+(this.state.fechaHasta2)+" 23:59:59' "+SQL_add+" group by tipo1 )",
              field :[],
              order_by: "valor",
              return:['tipo1','valor']
            },
            {
              table : "( SELECT tipotarjeta,cast(sum(subtotal) as decimal(20,2)) as valor FROM venta_tipo_pagov2 v inner join tipo_pago t  on t.forma_pago=v.forma_pago where fecha>='"+(this.state.fechaDesde2)+" 00:00:00' and  fecha<='"+(this.state.fechaHasta2)+" 23:59:59' "+SQL_add+" group by tipotarjeta having not trim(tipotarjeta)='' )",
              field :[],
              order_by: "valor",
              return:['tipotarjeta','valor']
            }
            ,
            {
              table : "( SELECT tipoaplicacion,cast(sum(subtotal) as decimal(20,2)) as valor FROM venta_tipo_pagov2 v inner join tipo_pago t  on t.forma_pago=v.forma_pago where fecha>='"+(this.state.fechaDesde2)+" 00:00:00' and  fecha<='"+(this.state.fechaHasta2)+" 23:59:59' "+SQL_add+" group by tipoaplicacion having not trim(tipoaplicacion)='' )",
              field :[],
              order_by: "valor",
              return:['tipoaplicacion','valor']
            }
          ]
        }]
        var result = Selectbd(select)
        if(result){
            result.then((value) => {
            if(value[1].status==="ok"){
                
                const dataSemana1 = value[1].result.map(item => parseFloat(item.ticket1));
                const dataSemana2 = value[1].result.map(item => parseFloat(item.ticket2));

                const dataSemana1V = value[2].result.map(item => parseFloat(item.ticket1));
                const dataSemana2V = value[2].result.map(item => parseFloat(item.ticket2));

                const pago_medio = value[3].result.map(item => (item.tipo1));
                const pago_valor = value[3].result.map(item => parseFloat(item.valor));

                const pago_medioT = value[4].result.map(item => (item.tipotarjeta));
                const pago_valorT = value[4].result.map(item => parseFloat(item.valor));

                const pago_medioD = value[5].result.map(item => (item.tipoaplicacion));
                const pago_valorD = value[5].result.map(item => parseFloat(item.valor));

                //console.log(pago_valor)
                // forma_pago, valor
                this.setState({
                    dataticket : value[1].result,
                    dataLineChart: {
                        labels: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"],
                        datasets: [
                          {
                            label: "Rango 1",
                            data: dataSemana1,
                            borderColor: "#007bff",
                            backgroundColor: "rgba(0, 123, 255, 0.2)",
                            fill: true,
                          },
                          {
                            label: "Rango 2",
                            data: dataSemana2,
                            borderColor: "#dc3545",
                            backgroundColor: "rgba(220, 53, 69, 0.2)",
                            fill: true,
                          },
                        ],
                      },
                      dataLineChartVentas: {
                        labels: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"],
                        datasets: [
                          {
                            label: "Rango 1",
                            data: dataSemana1V,
                            borderColor: "#007bff",
                            backgroundColor: "rgba(0, 123, 255, 0.2)",
                            fill: true,
                          },
                          {
                            label: "Rango 2",
                            data: dataSemana2V,
                            borderColor: "#dc3545",
                            backgroundColor: "rgba(220, 53, 69, 0.2)",
                            fill: true,
                          },
                        ],
                      },
                      chartData:{
                        labels: pago_medio,
                        datasets: [
                          {
                            label: "Métodos de Pago en  General",
                            data: pago_valor,
                            backgroundColor: "rgba(255, 99, 132, 0.5)",
                          },
                        ],
                      }
                      ,
                      chartDataT:{
                        labels: pago_medioT,
                        datasets: [
                          {
                            label: "Métodos de Pago en  Tarjeta",
                            data: pago_valorT,
                            backgroundColor: "rgba(255, 99, 132, 0.5)",
                          },
                        ],
                      } 
                      ,
                      chartDataD:{
                        labels: pago_medioD,
                        datasets: [
                          {
                            label: "Tipo de Delivery",
                            data: pago_valorD,
                            backgroundColor: "rgba(255, 99, 132, 0.5)",
                          },
                        ],
                      } 
                })


            }else{
                this.setState({
                    dataticket : [] 
                })
            }
            });
        }
  
    }
  };

  render() {
    return (
      <Container className="mt-5">
        <Card className="shadow-lg p-4">
          <Card.Body>
            <h2 className="mb-4 text-primary text-center">Reporte Gerencial</h2>

            {/* ✅ Selección de Locales con opción "Todos" */}
            <Form.Group className="mb-3">
              <Form.Label>Seleccionar Locales</Form.Label>
              <Form.Control as="select" multiple onChange={this.handleLocalesChange}>
                <option value="Todos">Todos</option>
                {this.state.locales.map((local) => (
                  <option key={local.id_usuario_globales} value={local.id_usuario_globales} selected>
                    {local.etiqueta}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* ✅ Filtros de Fecha con 2 Rangos Comparativos */}
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Desde (Rango 1)</Form.Label>
                  <Form.Control type="date" name="fechaDesde1" value={this.state.fechaDesde1} onChange={(e) => this.setState({ fechaDesde1: e.target.value })} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Hasta (Rango 1)</Form.Label>
                  <Form.Control type="date" name="fechaHasta1" value={this.state.fechaHasta1} onChange={(e) => this.setState({ fechaHasta1: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Desde (Rango 2)</Form.Label>
                  <Form.Control type="date" name="fechaDesde2" value={this.state.fechaDesde2} onChange={(e) => this.setState({ fechaDesde2: e.target.value })} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Hasta (Rango 2)</Form.Label>
                  <Form.Control type="date" name="fechaHasta2" value={this.state.fechaHasta2} onChange={(e) => this.setState({ fechaHasta2: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Tipo de Reporte</Form.Label>
              <Form.Control as="select" onChange={(e) => this.setState({ tipoReporte: e.target.value })}>
                <option value="Ventas">Ventas</option>
                <option value="Reporte por horas de la semana">Reporte por horas de la semana</option>
              </Form.Control>
            </Form.Group>

            <Button variant="primary" className="w-100" onClick={this.generarReporte}>
              Generar Reporte
            </Button>
          </Card.Body>
        </Card>

        {this.state.dataLineChart && (
          <Card className="mt-4 shadow-lg p-4">
            <Card.Body>
              <h3 className="mb-3 text-primary">Comparación de Ticket Promedio</h3>
              <Line data={this.state.dataLineChart} options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                tooltip: {
                  enabled: true, // Activa el tooltip
                  backgroundColor: "rgba(0,0,0,0.8)", // Color de fondo del tooltip
                  titleColor: "white", // Color del título
                  bodyColor: "white", // Color del contenido
                },
                datalabels: {
                  display: true,
                  color: "black",
                  font: {
                    size: 9,
                    weight: "bold",
                  },
                  backgroundColor: "white", // Fondo para la etiqueta
                  borderRadius: 3,
                  padding: 5,
                },
              },
              scales: {
                y: {
                  beginAtZero: false,
                },
              }}}  />
            </Card.Body>
          </Card>
        )}
        {this.state.dataLineChartVentas && (
          <Card className="mt-4 shadow-lg p-4">
            <Card.Body>
              <h3 className="mb-3 text-primary">Comparación de Ventas Netas</h3>
              <Line data={this.state.dataLineChartVentas} options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                tooltip: {
                  enabled: true, // Activa el tooltip
                  backgroundColor: "rgba(0,0,0,0.8)", // Color de fondo del tooltip
                  titleColor: "white", // Color del título
                  bodyColor: "white", // Color del contenido
                },
                datalabels: {
                  display: true,
                  color: "black",
                  font: {
                    size: 9,
                    weight: "bold",
                  },
                  backgroundColor: "white", // Fondo para la etiqueta
                  borderRadius: 3,
                  padding: 5,
                },
              },
              scales: {
                y: {
                  beginAtZero: false,
                },
              }}} />
            </Card.Body>
          </Card>
        )}
      {this.state.chartData && (
          <Card className="mt-4 shadow-lg p-4">
            <Card.Body>
              <h3 className="mb-3 text-primary">Métodos de Pago en General</h3>
              <Bar data={this.state.chartData} options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                    labels: {
                      color: "#333",
                      font: {
                        size: 14,
                        family: "Arial, sans-serif",
                        weight: "bold",
                      },
                      boxWidth: 20,
                    },
                  },
                  tooltip: {
                    enabled: true,
                    backgroundColor: "rgba(0,0,0,0.8)",
                    titleColor: "#FFD700",
                    titleFont: {
                      size: 14,
                      weight: "bold",
                    },
                    bodyColor: "#FFFFFF",
                    bodyFont: {
                      size: 12,
                    },
                    borderColor: "#FFD700",
                    borderWidth: 1,
                    padding: 10,
                    cornerRadius: 6,
                    callbacks: {
                      label: function (context) {
                        let value = context.raw;
                        return `$${value.toFixed(2)}`; // Formato en dólares con 2 decimales
                      },
                    },
                  },
                  datalabels: {
                    display: true,
                    color: "#000",
                    font: {
                      size: 9,
                      family: "Arial, sans-serif",
                      weight: "bold",
                    },
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    borderRadius: 4,
                    padding: 6,
                    align: "end",
                    anchor: "end",
                    formatter: (value) => `$${value.toFixed(2)}`, // Formato de dólares en etiquetas
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      color: "#333",
                      font: {
                        size: 12,
                      },
                    },
                  },
                  y: {
                    beginAtZero: false,
                    ticks: {
                      color: "#333",
                      font: {
                        size: 12,
                      },
                      callback: function (value) {
                        return `$${value.toFixed(2)}`; // Formato en dólares para los ejes
                      },
                    },
                    grid: {
                      color: "rgba(200, 200, 200, 0.5)",
                    },
                  },
                },
              }} />
            </Card.Body>
          </Card>
        )}
       {this.state.chartDataT && (
          <Card className="mt-4 shadow-lg p-4">
            <Card.Body>
              <h3 className="mb-3 text-primary">Métodos de Pago en Tarjeta</h3>
              <Bar data={this.state.chartDataT} options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                    labels: {
                      color: "#333",
                      font: {
                        size: 14,
                        family: "Arial, sans-serif",
                        weight: "bold",
                      },
                      boxWidth: 20,
                    },
                  },
                  tooltip: {
                    enabled: true,
                    backgroundColor: "rgba(0,0,0,0.8)",
                    titleColor: "#FFD700",
                    titleFont: {
                      size: 14,
                      weight: "bold",
                    },
                    bodyColor: "#FFFFFF",
                    bodyFont: {
                      size: 12,
                    },
                    borderColor: "#FFD700",
                    borderWidth: 1,
                    padding: 10,
                    cornerRadius: 6,
                    callbacks: {
                      label: function (context) {
                        let value = context.raw;
                        return `$${value.toFixed(2)}`; // Formato en dólares con 2 decimales
                      },
                    },
                  },
                  datalabels: {
                    display: true,
                    color: "#000",
                    font: {
                      size: 9,
                      family: "Arial, sans-serif",
                      weight: "bold",
                    },
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    borderRadius: 4,
                    padding: 6,
                    align: "end",
                    anchor: "end",
                    formatter: (value) => `$${value.toFixed(2)}`, // Formato de dólares en etiquetas
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      color: "#333",
                      font: {
                        size: 12,
                      },
                    },
                  },
                  y: {
                    beginAtZero: false,
                    ticks: {
                      color: "#333",
                      font: {
                        size: 12,
                      },
                      callback: function (value) {
                        return `$${value.toFixed(2)}`; // Formato en dólares para los ejes
                      },
                    },
                    grid: {
                      color: "rgba(200, 200, 200, 0.5)",
                    },
                  },
                },
              }} />
            </Card.Body>
          </Card>
        )} 
        {this.state.chartDataD && (
          <Card className="mt-4 shadow-lg p-4">
            <Card.Body>
              <h3 className="mb-3 text-primary">Tipos en Delivery</h3>
              <Bar data={this.state.chartDataD} options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                    labels: {
                      color: "#333",
                      font: {
                        size: 14,
                        family: "Arial, sans-serif",
                        weight: "bold",
                      },
                      boxWidth: 20,
                    },
                  },
                  tooltip: {
                    enabled: true,
                    backgroundColor: "rgba(0,0,0,0.8)",
                    titleColor: "#FFD700",
                    titleFont: {
                      size: 14,
                      weight: "bold",
                    },
                    bodyColor: "#FFFFFF",
                    bodyFont: {
                      size: 12,
                    },
                    borderColor: "#FFD700",
                    borderWidth: 1,
                    padding: 10,
                    cornerRadius: 6,
                    callbacks: {
                      label: function (context) {
                        let value = context.raw;
                        return `$${value.toFixed(2)}`; // Formato en dólares con 2 decimales
                      },
                    },
                  },
                  datalabels: {
                    display: true,
                    color: "#000",
                    font: {
                      size: 9,
                      family: "Arial, sans-serif",
                      weight: "bold",
                    },
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    borderRadius: 4,
                    padding: 6,
                    align: "end",
                    anchor: "end",
                    formatter: (value) => `$${value.toFixed(2)}`, // Formato de dólares en etiquetas
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      color: "#333",
                      font: {
                        size: 12,
                      },
                    },
                  },
                  y: {
                    beginAtZero: false,
                    ticks: {
                      color: "#333",
                      font: {
                        size: 12,
                      },
                      callback: function (value) {
                        return `$${value.toFixed(2)}`; // Formato en dólares para los ejes
                      },
                    },
                    grid: {
                      color: "rgba(200, 200, 200, 0.5)",
                    },
                  },
                },
              }}/>
            </Card.Body>
          </Card>
        )} 

        <Card className="mt-4 shadow-lg p-4">
          <Card.Body>
            <h3 className="mb-3 text-primary">Mapa de Calor de Ventas</h3>
            <div
              id="heatmapContainer"
              style={{
                width: "100%",
                height: "400px",
                position: "relative",
                backgroundColor: "#f8f9fa",
                border: "1px solid #ddd",
              }}
            ></div>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  componentDidMount (){
    const select = [{
          type: 'consulta', data:
          [{
            table : "(SELECT id_usuario_globales,etiqueta FROM usuario_globales  where activo=1 )",
            field :[],
            order_by: "etiqueta",
            return:['id_usuario_globales','etiqueta']
          }]
        }]
        var result = Selectbd(select)
        if(result){
          result.then((value) => {
            if(value[1].status==="ok"){
              this.setState({
                locales : value[1].result
              },()=>this.generarReporte())
            }else{
              this.setState({
                locales : [],
                selectedLocales:[]
              })
            }
          });
        }
    }
}

export default GetReporteGerencial;
